import ValidationErrors from '../shared/ValidationErrors';
import { isValidationError } from './errorCodes';
import './PreviewError/styles.scss';

export default function PreviewError({ error, validationErrors }) {
  return (
    <div className="wkp-preview-error">
      {validationErrors ? (
        <>
          <p>
            The Data Connect workflow is currently in an invalid state. The following errors must be resolved before you
            can preview the output of this Data Connect workflow:
          </p>
          <ValidationErrors validationErrors={validationErrors} />
        </>
      ) : isValidationError(error) ? (
        <p>The data flow is currently in an invalid state.</p>
      ) : (
        <p>{error.message}</p>
      )}
    </div>
  );
}
